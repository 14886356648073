import { AbstractControl } from '@angular/forms';
import { FactoryDbValidator } from './database-validators/factory-db-validator';

export class CustomValidator {
  static Match(firstControlName: string, secondControlName: string) {
    return (AC: AbstractControl) => {
      const firstControl = AC.get(firstControlName);
      const secondControl = AC.get(secondControlName);
      if (firstControl == null) {
        throw new Error(
          'CustomValidator.Match(firstControlName, secondControlName): the first parameter is not found in parent group'
        );
      }
      if (secondControl == null) {
        throw new Error(
          'CustomValidator.Match(firstControlName, secondControlName): the second parameter is not found in parent group'
        );
      }
      if (firstControl.value !== secondControl.value) {
        AC.get(secondControlName).setErrors({ matchfield: true });
      } else {
        return null;
      }
    };
  }

  static JsonValidator(control: AbstractControl): { JsonValidator: any } | null {
    const { value } = control;
    if (!value) return null;
    try {
      JSON.parse(value);
      return null;
    } catch (error) {
      return { JsonValidator: value };
    }
  }

  static HtmlValidator(control: AbstractControl): { HtmlValidator: any } | null {
    const { value } = control;
    if (!value) return null;
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(value, 'text/xml');
      if (doc.documentElement.querySelector('parsererror')) {
        let txtErrors = '';
        const errors = doc.documentElement.querySelectorAll('div');
        errors.forEach(error => (txtErrors = txtErrors + error.innerHTML + '\n'));
        return { HtmlValidator: txtErrors };
      }
      return null;
    } catch (error) {
      return { HtmlValidator: value };
    }
  }

  static ValidDatabaseField(control: AbstractControl): any | null {
    return /^[A-Za-z0-9\-_]*$/.test(control.value) ? null : { ValidDatabaseField: control.value };
  }

  static UniqueValue(fieldName: string, allValuesFieldName: string) {
    return (AC: AbstractControl) => {
      const fieldControl = AC.get(fieldName);
      const allValuesControl = AC.get(allValuesFieldName);
      if (!fieldControl) {
        throw new Error(
          'CustomValidator.UniqueValue(fieldName, allValuesFieldName): the first parameter is not found in form'
        );
      }
      if (!allValuesControl) {
        throw new Error(
          'CustomValidator.UniqueValue(fieldName, allValuesFieldName): the second parameter is not found in form'
        );
      }
      if (allValuesControl.value && allValuesControl.value.length > 0) {
        return allValuesControl.value.includes(fieldControl.value)
          ? { UniqueValue: fieldControl.value }
          : null;
      }
      return null;
    };
  }

  static DatabaseValidator(validator: any): any {
    return (AC: AbstractControl) => {
      try {
        const dbValidator = FactoryDbValidator.getValidatorByName(validator.name, {
          control: AC,
          validator: validator,
        });
        const validationResult = dbValidator.validate();
        if (validationResult) {
          return { DatabaseValidator: validator.message ? validator : validationResult };
        }
        return null;
      } catch (e) {
        console.log('Error in validator factory or validation execution:', e);
      }
    };
  }
}
