import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from 'src/app/core/services/api-base.service';

@Injectable({
  providedIn: 'root',
})
export class UserAccountDataService extends ApiBaseService {
  switchAccount(accountId: string): Observable<any> {
    const httpOptions = this.getHttpOptions(true);
    return this.get<any>(`${this.url}/${accountId}`, httpOptions);
  }
}
