import { Injectable } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmitEvent, Events, EventBusService } from 'src/app/core/services/event-bus/event-bus.service';
import { User } from 'src/app/core/models/user.model';
import { UserSettings } from 'src/app/core/models/user-settings.model';
import { CacheService } from 'src/app/core/services/cache/cache.service';
import { UserDataService } from 'src/app/core/services/user/user-data.service';
import { UserAccountDataService } from 'src/app/core/services/user/user-account-data.service';
import { Catalog } from 'src/app/core/models/catalog.model';
import { UserCatalogDataService } from 'src/app/core/services/user/user-catalog-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: User;
  private token: string = null;
  private dataToken: string = null;
  private language: string = null;
  private account: any = null;
  private version = environment.VERSION;
  protected tokenSubject: Subject<any>;
  public tokenObserver: Observable<any>;
  public userSettingsChangeSubject: Subject<any>;
  private cacheExpiration = Number.MAX_VALUE;
  public catalogs: Catalog[];
  public defaultCatalog: Catalog;
  public unsavedData: boolean;

  constructor(
    private cacheService: CacheService,
    private eventBus: EventBusService,
    private userDataService: UserDataService,
    private userAccountDataService: UserAccountDataService,
    private userCatalogDataService: UserCatalogDataService
  ) {
    this.loadStorage();

    this.userDataService.init({ entityName: 'token', route: 'auth/token', cache: { enabled: false } });
    this.userAccountDataService.init({
      entityName: 'account',
      route: 'auth/switch_account',
      cache: { enabled: false },
    });
    this.userCatalogDataService.init({
      entityName: 'catalog',
      route: 'common/change_default_catalog',
      cache: { enabled: false },
    });
    this.tokenSubject = new Subject<any>();
    this.userSettingsChangeSubject = new Subject<any>();
    this.tokenObserver = this.tokenSubject.asObservable();
    this.unsavedData = false;
  }

  getModuleSettings(collection: string) {
    const moduleSettings = this.settings.dynamicList || {};
    return moduleSettings[collection];
  }

  getUserData(userData: any): Subscription {
    return this.userDataService.getUserData(userData).subscribe(data => {
      this.token = data.token || this.token;
      this.dataToken = data.data_token || this.dataToken;
      this.user = data.user ? (data.user as User) : this.user;
      this.account = data.account || this.account;
      this.language = data.language || this.language;
      this.catalogs = data.account.catalogs || this.catalogs;
      this.defaultCatalog = data.defaultCatalog || this.defaultCatalog;
      this.saveStorage();

      this.tokenSubject.next();
    });
  }

  getUserDataPromise(userData: any): Promise<void> {
    this.removeStorage();
    const logoUrl = userData.logoUrl;
    delete userData.logoUrl;
    return new Promise<void>(async resolve => {
      const data = await this.userDataService.getUserData(userData).toPromise();
      this.token = data.token || this.token;
      this.dataToken = data.data_token || this.dataToken;
      this.user = data.user ? (data.user as User) : this.user;
      this.account = data.account || this.account;
      this.language = data.language || this.language;
      this.catalogs = data.account.catalogs || this.catalogs;
      this.defaultCatalog = data.user.defaultCatalog || this.defaultCatalog;
      this.saveStorage();
      this.cacheService.setItem('logoUrl', logoUrl, false, this.cacheExpiration);
      resolve();
    });
  }

  isLogged(): boolean {
    return this.getToken() != null;
  }

  logout(): void {
    this.removeStorage();

    window.location.href = environment.logout_url;
  }

  changePassword(password: string): void {
    console.log(`changePassword method: ${password}`);
  }

  loadStorage(): void {
    if (this.cacheService.getItem('token')) {
      this.token = this.cacheService.getItem('token');
      this.dataToken = this.cacheService.getItem('dataToken');
      this.user = this.cacheService.getItem('user');
      this.language = this.cacheService.getItem('language');
      this.account = this.cacheService.getItem('account');
      this.catalogs = this.cacheService.getItem('catalogs');
      this.defaultCatalog = this.cacheService.getItem('defaultCatalog');
      if (this.language == 'null') {
        this.language = null;
      }
    } else {
      this.token = null;
      this.dataToken = null;
      this.user = null;
      this.language = null;
      this.account = null;
      this.catalogs = null;
      this.defaultCatalog = null;
    }
  }

  saveStorage(): void {
    this.eventBus.emit(new EmitEvent(Events.CachePreffix, this.user.username));

    this.cacheService.setItem('token', this.token, false, this.cacheExpiration);
    this.cacheService.setItem('dataToken', this.dataToken, false, this.cacheExpiration);
    this.cacheService.setItem('user', this.user, false, this.cacheExpiration);
    this.cacheService.setItem('language', this.language, false, this.cacheExpiration);
    this.cacheService.setItem('account', this.account, false, this.cacheExpiration);
    this.cacheService.setItem('catalogs', this.catalogs, false, this.cacheExpiration);
    this.cacheService.setItem('defaultCatalog', this.defaultCatalog, false, this.cacheExpiration);
  }

  removeStorage(): void {
    this.cacheService.removeItem('token');
    this.cacheService.removeItem('dataToken');
    this.cacheService.removeItem('user');
    this.cacheService.removeItem('language');
    this.cacheService.removeItem('account');
    this.cacheService.removeItem('catalogs');
    this.cacheService.removeItem('defaultCatalog');

    this.token = null;
    this.dataToken = null;
    this.user = null;
    this.account = null;
    this.language = null;
    this.catalogs = null;
    this.defaultCatalog = null;
  }

  getUser(): User {
    return this.getItem('user');
  }

  getToken(): string {
    return this.getItem('token');
  }

  getDataToken(): string {
    return this.getItem('dataToken');
  }

  getLanguage(): string {
    return this.getItem('language');
  }

  getAccount(): any {
    return this.getItem('account');
  }

  getDefaultCatalog(): Catalog {
    const catalogId = this.user?.defaultCatalog?.id
      ? this.user?.defaultCatalog?.id
      : this.user?.defaultCatalog;
    if (!this.catalogs || !this.user?.catalogs) {
      return null;
    }
    if (this.catalogs.some(x => x.id === catalogId)) {
      return this.catalogs.find(x => x.id === catalogId) ?? ({ id: catalogId } as Catalog);
    }
    return null;
  }

  getCatalogs(): Catalog[] {
    const catalogs = this.getItem('catalogs');
    return catalogs?.filter(x => this.user?.catalogs?.includes(x.id));
  }

  getLogoUrl() {
    return this.getItem('logoUrl');
  }

  private getItem(key: string): any {
    if (!this[key]) {
      this[key] = this.cacheService.getItem(key);
    }

    return this[key];
  }

  setLanguage(language: string): void {
    if (this.user != null) {
      this.user.language = language;
    }
    this.language = language;
    this.saveStorage();
  }

  getVersion(): string {
    return this.version;
  }

  switchAccount(accountId: string): Subscription {
    return this.userAccountDataService.switchAccount(accountId).subscribe(data => {
      this.account = data.account || this.account;
      this.dataToken = data.data_token || this.dataToken;
      this.defaultCatalog = data.defaultCatalog || this.defaultCatalog;
      this.user.defaultCatalog = data.defaultCatalog || this.defaultCatalog;
      this.saveStorage();

      this.tokenSubject.next(data);
      // Update account in Aragorn
      window.open(`${environment.aragorn_url}/auth/switch_account/${accountId}`, '_self');
    });
  }

  switchCatalog(catalogId: string): Subscription {
    return (
      this.userCatalogDataService.switchCatalog(catalogId)?.subscribe(data => {
        this.account = data.account || this.account;
        this.dataToken = data.data_token || this.dataToken;
        this.defaultCatalog = data.user.defaultCatalog;
        this.user.defaultCatalog = this.defaultCatalog;
        this.saveStorage();

        this.tokenSubject.next(data);
        // Update account in Aragorn
        window.open(`${environment.aragorn_url}/auth/switch_catalog/${this.defaultCatalog.id}`, '_self');
      }) || null
    );
  }

  get settings(): UserSettings {
    const settings = this.cacheService.getItem('user_settings');
    if (settings) {
      if (!settings['dynamicForm']) {
        settings['dynamicForm'] = {};
      }
    }
    return settings ? (settings as UserSettings) : new UserSettings();
  }

  set settings(settings: UserSettings) {
    const mergedSettings = { ...this.settings, ...settings };
    this.cacheService.setItem('user_settings', mergedSettings, false, this.cacheExpiration);
    this.userSettingsChangeSubject.next(mergedSettings);
  }

  getUsersByIds(ids: string[]): Promise<User[]> {
    return this.userDataService.getUsersByIds(ids).toPromise();
  }
}
