import { AbstractControl } from '@angular/forms';

export class FormHelper {
  public invalidControlForm(controlForm: AbstractControl): boolean {
    return (controlForm.touched || controlForm.dirty) && !controlForm.valid;
  }

  public dirtyOrToucheControlForm(controlForm: AbstractControl): boolean {
    return controlForm.touched || controlForm.dirty;
  }

  static isDirtyAndNotEmpty(control: AbstractControl): boolean {
    return control.dirty && control.value != null && control.value != undefined;
  }

  static getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }
}
