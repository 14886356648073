import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CacheService } from 'src/app/core/services/cache/cache.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { OptionsService } from '../api-base.service';
import { DataService } from '../data.service';
import { CreateObservable } from '../create-observable';
@Injectable({
  providedIn: 'root',
})
export class AppMenuService extends DataService {
  protected options: OptionsService = {
    route: 'common/app_menu',
    entityName: 'app_menu',
    cache: { enabled: true, localePrefix: true },
  };
  NoInitMenuDataSubject: BehaviorSubject<any>;
  outsideItemMenuHandler: Subject<any>;
  menuAlreadyInitialized: CreateObservable;

  constructor(
    protected httpClient: HttpClient,
    protected cacheService: CacheService,
    protected userService: UserService
  ) {
    super(httpClient, cacheService);
    this.NoInitMenuDataSubject = new BehaviorSubject<any>(null);
    this.outsideItemMenuHandler = new Subject<any>();
    this.menuAlreadyInitialized = new CreateObservable();

    this.options.cache = {
      enabled: true,
      localePrefix: true,
      customPrefix: `catalog_id=${this.userService.getDefaultCatalog()?.id}`,
    };
  }

  activateEntryByName(entryName) {
    this.outsideItemMenuHandler.next(entryName);
  }

  getData(dontResetMenu = false): void {
    this.get<any>(this.url).subscribe(data => {
      if (data) {
        this.data = data.map(menu => ({
          ...menu,
        }));
        if (dontResetMenu) {
          this.NoInitMenuDataSubject.next(this.data);
        } else {
          this.dataSubject.next(this.data);
          this.menuAlreadyInitialized.next(this.data);
        }
      }
    });
  }

  getMenuLabel(name: string, label: string): string {
    if (!this.data) {
      return label;
    }
    const entry = this.searchEntry(this.data, name);
    return entry ? entry.label : label;
  }

  getMenuLink(name: string): string {
    if (!this.data) return '';
    const entry = this.searchEntry(this.data, name);
    return entry ? entry.link : '';
  }

  private searchEntry(menus: any[], propertyName: string): any {
    let result = null;
    for (const menu of menus) {
      result = menu.entries.find(element => element.name === propertyName);
      if (result) {
        return result;
      }
      for (const entry of menu.entries) {
        if (entry.childs.length) {
          result = entry.childs.find(grandChild => grandChild.name === propertyName) ?? null;
        } else if (entry.name === propertyName) {
          result = entry;
        }
        if (result) {
          return result;
        }
      }
    }
    return result;
  }

  private findLinkInHtmlDescription(entry, url): string {
    if (entry.description && Array.isArray(entry.description)) {
      for (const description of entry.description) {
        if (url.includes(description.url)) {
          return description.html;
        }
      }
    }
    return null;
  }

  public getDescription(url: string, menus: any): string {
    if (!this.data) {
      return null;
    }
    for (const menu of menus) {
      for (const entry of menu.entries) {
        if (entry.childs.length === 0) {
          const htmlDescription = this.findLinkInHtmlDescription(entry, url);
          if (htmlDescription) {
            return htmlDescription;
          }
        } else {
          for (const childEntry of entry.childs) {
            const htmlDescription = this.findLinkInHtmlDescription(childEntry, url);
            if (htmlDescription) {
              return htmlDescription;
            }
          }
        }
      }
    }
    return null;
  }
}
