import { Component, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location, registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { InitialRouteService } from './core/services/config/initial-route.service';
import { environment } from '../environments/environment';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import { UserService } from './core/services/user/user.service';
import { NotificationService } from './core/services/notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'gandalf';

  locales = {
    es: localeEs,
    en: localeEn,
    pt: localePt,
    nl: localeNl,
    de: localeDe,
  };

  constructor(
    private translate: TranslateService,
    private initialRoute: InitialRouteService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private renderer: Renderer2,
    private userService: UserService,
    private notificationService: NotificationService,
    @Inject(DOCUMENT) private doc: any
  ) {
    this.updateBodyClass(this.translate.currentLang);
    this.translate.onLangChange.subscribe(event => {
      this.updateBodyClass(event.lang);
    });
    this.initialRoute.init();
    this.initialRoute.dataObserver.subscribe(data => {
      if (data && data.path && this.location.path() === '') {
        const config = this.router.config;
        config.push({
          path: '',
          redirectTo: data.path,
          pathMatch: 'full',
        });
        this.router.resetConfig(config);
        this.router.navigate([data.path], { relativeTo: this.route });
      }
    });
    this.initialRoute.getData();

    this.translate.setDefaultLang('en');
    registerLocaleData(this.locales[this.translate.currentLang]);
  }

  updateBodyClass(lang: string) {
    this.renderer.removeClass(document.body, 'es');
    this.renderer.removeClass(document.body, 'en');
    this.renderer.addClass(document.body, `${lang}`);
  }

  getHotjarScript(): string {
    return `(function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3697537, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`;
  }

  getMixPanelScript() {
    return `
    (function(e,b){if(!b.__SV){var a,f,i,g;window.mixpanel=b;b._i=[];b.init=function(a,e,d){function f(b,h){var a=h.split(".");2==a.length&&(b=b[a[0]],h=a[1]);b[h]=function(){b.push([h].concat(Array.prototype.slice.call(arguments,0)))}}var c=b;"undefined"!==typeof d?c=b[d]=[]:d="mixpanel";c.people=c.people||[];c.toString=function(b){var a="mixpanel";"mixpanel"!==d&&(a+="."+d);b||(a+=" (stub)");return a};c.people.toString=function(){return c.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
    for(g=0;g<i.length;g++)f(c,i[g]);b._i.push([a,e,d])};b.__SV=1.2;a=e.createElement("script");a.type="text/javascript";a.async=!0;a.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";f=e.getElementsByTagName("script")[0];f.parentNode.insertBefore(a,f)}})(document,window.mixpanel||[]);`;
  }

  ngOnInit() {
    if (environment.aragorn_url === 'https://aragorn.dev.app.compliance.eprodat.com') {
      const hotjarScript = this.doc.createElement('script');
      hotjarScript.type = 'text/javascript';
      hotjarScript.innerHTML = this.getHotjarScript();

      const mixpanelScript = this.doc.createElement('script');
      mixpanelScript.type = 'text/javascript';
      mixpanelScript.innerHTML = this.getMixPanelScript();

      const head = this.doc.getElementsByTagName('head')[0];
      head.appendChild(hotjarScript);
      head.appendChild(mixpanelScript);
    }
  }
}
