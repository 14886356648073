import { FormHelper } from '../form-helper';
import { BaseDbValidator } from './base-db-validator';
import { ValidatorResponse } from './validator.model';

export class MaxLengthDbValidator extends BaseDbValidator {
  validate(): ValidatorResponse | null {
    if (FormHelper.isDirtyAndNotEmpty(this.control) && this.control.value?.length > this.validator.value) {
      return {
        message: 'The length of value can not be greater than',
        params: this.params,
      };
    }
    return null;
  }
}
