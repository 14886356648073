import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/core/services/user/user.service';
import { LanguageComponent } from './language.component';

@NgModule({
  declarations: [LanguageComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [LanguageComponent, TranslateModule],
})
export class LanguageModule {
  constructor(private translate: TranslateService, private userService: UserService) {
    const langs = environment.language.languages.map(element => element.code);
    const userLanguage = this.userService.getLanguage();

    this.translate.addLangs(langs);
    this.translate.setDefaultLang('en');

    if (userLanguage) {
      this.translate.use(userLanguage);
    } else {
      const browserLang = translate.getBrowserLang();
      this.translate.use(langs.includes(browserLang) ? browserLang : 'en');
    }
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
