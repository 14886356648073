import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';
import { MainComponent } from './main/main.component';
import { UsermenuComponent } from './header/usermenu/usermenu.component';
import { ModalComponent } from '../components/modal/modal.component';
import { ChangepasswordModalComponent } from './header/usermenu/changepassword-modal/changepassword-modal.component';
import { LanguageModule } from '../components/language/language.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppMenuComponent } from './header/app-menu/app-menu.component';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { StatusComponent } from './main/status/status.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgSelect2Module } from 'ng-select2';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    MainComponent,
    UsermenuComponent,
    ModalComponent,
    ChangepasswordModalComponent,
    AppMenuComponent,
    BreadcrumbComponent,
    StatusComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgApexchartsModule,
    LanguageModule,
    NgxSpinnerModule,
    NgSelect2Module,
    PipesModule,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
