import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CssColumnPipe } from './css-column.pipe';
import { FileSizePipe } from './filesize.pipe';
import { ProgressColorPipe } from './progress-color.pipe';
import { TransformTimePipe } from './transform-time.pipe';
import { FileSearchPipe } from './file-search.pipe';
import { SafePipe } from './safe.pipe';
import { DashboardCssColumnPipe } from './dashboard-css-column.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ArrayFilterPipe } from './filter-array-objects.pipe';
import { BreaklinesPipe } from './breaklines.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FileSizePipe,
    ProgressColorPipe,
    CssColumnPipe,
    TransformTimePipe,
    FileSearchPipe,
    SafePipe,
    DashboardCssColumnPipe,
    SafeHtmlPipe,
    ArrayFilterPipe,
    BreaklinesPipe,
  ],
  exports: [
    FileSizePipe,
    ProgressColorPipe,
    CssColumnPipe,
    TransformTimePipe,
    FileSearchPipe,
    SafePipe,
    DashboardCssColumnPipe,
    SafeHtmlPipe,
    ArrayFilterPipe,
    BreaklinesPipe,
  ],
})
export class PipesModule {}
