import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { NotificationService } from '../services/notification/notification.service';
import { LoggingService } from './loggin.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(@Inject(Injector) private injector: Injector, private logger: LoggingService) {
    super();
  }

  private get notificationService(): NotificationService {
    return this.injector.get(NotificationService);
  }

  handleError(error: Error): void {
    this.logger.logError(error);
    this.notificationService.showError(error.message || 'An error has occurred.');
  }
}
