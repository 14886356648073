import { ParentEntity } from 'src/app/core/models/parent-entity';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObserverBehavior } from '../models/observer-behavior.model';
import { CacheService } from './cache/cache.service';
import { CurrentRequests } from './current-requests';
import { skip } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export interface OptionsService {
  entityName: string;
  route?: string;
  id?: string;
  cache?: StatusCache;
  parentEntity?: ParentEntity;
}

export interface StatusCache {
  enabled: boolean;
  prefix?: string;
  localePrefix?: boolean;
  customPrefix?: string;
}

export interface header {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiBaseService {
  dataObserver: Observable<any>;
  protected dataSubject: BehaviorSubject<any>;
  protected data: any;
  protected statusCache: StatusCache;
  public baseUrl: string;
  protected route: string;
  protected entityName: string;
  protected id: string;
  protected options: OptionsService;
  protected headers: header[];
  static requests: CurrentRequests = new CurrentRequests();
  public parentEntity: ParentEntity;
  auxUrl;

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    protected httpClient: HttpClient,
    protected cacheService: CacheService,
    protected translate?: TranslateService
  ) {
    this.dataSubject = new BehaviorSubject<any>(null);
    this.dataObserver = this.dataSubject.asObservable().pipe(skip(1));
    this.baseUrl = environment.root_api;
    this.headers = [];
  }

  get url(): string {
    if (this.parentEntity?.id) {
      return `${this.baseUrl}/${this.parentEntity.route}/${this.parentEntity.id}/${this.route}`;
    } else {
      return `${this.baseUrl}/${this.route}`;
    }
  }

  addHeader(header: header): void {
    this.headers.push(header);
  }

  getHeader(name: string): header {
    return this.headers.find(header => header.name === name);
  }

  addCustomHeader(name: string, value: any) {
    this.addHeader({ name: name, value: JSON.stringify(value) });
  }

  removeHeader(name: string): void {
    const index = this.headers.findIndex(header => header.name === name);
    if (index === -1) {
      return;
    }
    this.headers.splice(index, 1);
  }

  init(options: OptionsService = this.options): void {
    this.options = options;
    this.route = options.route || options.entityName;
    this.statusCache = options.cache || { enabled: false };
    this.entityName = options.entityName || null;
    this.id = options.id || null;
    this.parentEntity = options.parentEntity || null;
  }

  public getHttpOptions(formWithFile = false): any {
    return formWithFile ? { headers: new HttpHeaders() } : this.httpOptions;
  }

  protected getQueryString(params: HttpParams): string {
    return !params ? '' : `?${params.toString()}`;
  }
  protected getParentEntityUrlString(parentEntity: ParentEntity): string {
    return !parentEntity ? '' : `${parentEntity.route}/${parentEntity.id}/`;
  }

  protected getCacheKey(parentEntity: ParentEntity, params: HttpParams): string {
    const parentEntityString = this.getParentEntityUrlString(parentEntity);
    const queryString = this.getQueryString(params);

    return this.statusCache.customPrefix
      ? this.statusCache.customPrefix + ':' + parentEntityString + this.route + queryString
      : parentEntityString + this.route + queryString;
  }

  private removeUnnecessarySpaces(body: any): any {
    let result = null;
    if (body.constructor && body.constructor.name === 'FormData') {
      for (const key of body.keys()) {
        const value = body.get(key);
        if (value && value !== 'undefined' && value !== '') {
          let valueObject = null;
          try {
            valueObject = JSON.parse(value);
          } catch (e) {
            continue;
          }
          if (valueObject && (typeof valueObject === 'string' || valueObject instanceof String)) {
            let newValue = null;
            try {
              newValue = body.get(key).replaceAll('"', '').trim();
            } catch (e) {
              console.log(e);
            }
            if (newValue) {
              body.set(key, JSON.stringify(newValue));
            }
          }
        }
      }
      result = body;
    } else {
      const bodyObject = typeof body === 'object' ? body : JSON.parse(body);
      for (const key of Object.keys(bodyObject)) {
        const value = bodyObject[key];
        if ((value && typeof value === 'string') || value instanceof String) {
          bodyObject[key] = bodyObject[key].trim();
        }
      }
      result = typeof body === 'object' ? bodyObject : JSON.stringify(bodyObject);
    }
    return result;
  }

  request<T>(
    method: string,
    url: string,
    options: any,
    responseType?: 'arraybuffer' | 'blob' | 'text' | 'json'
  ): Observable<any> {
    if (!options) {
      options = this.getHttpOptions();
    }

    if (responseType) {
      options = { ...options, responseType };
    }

    if (this.headers.length > 0) {
      if (!options.headers) {
        options.headers = new HttpHeaders();
      }
      for (const header of this.headers) {
        options.headers = options.headers.set(header.name, header.value);
      }
    }

    if (options.body) {
      options.body = this.removeUnnecessarySpaces(options.body);
    }

    if (!this.statusCache?.enabled) {
      return this.httpClient.request<T>(method, url, options);
    }

    const newObserver = new ObserverBehavior();

    const request = ApiBaseService.requests.get(url);
    if (request) {
      request.getObserver().subscribe(data => {
        request.next(data);
      });
      return request.getObserver();
    } else {
      ApiBaseService.requests.add(url);
    }

    const cacheKey = this.getCacheKey(this.parentEntity, options?.params);
    const cacheData = this.cacheService.getItem(cacheKey, this.statusCache.localePrefix);
    if (cacheData) {
      newObserver.next(cacheData);
      ApiBaseService.requests.remove(url);
    } else {
      this.httpClient.request<T>(method, url, options).subscribe(data => {
        this.cacheService.setItem(cacheKey, data, this.statusCache.localePrefix);
        newObserver.next(data);
        ApiBaseService.requests.remove(url);
      });
    }
    return newObserver.getObserver();
  }

  get<T>(url, params = {}, responseType?: 'arraybuffer' | 'blob' | 'text' | 'json'): Observable<T> {
    return this.request<T>('GET', url, params, responseType);
  }

  post<T>(url, body, options): Observable<any> {
    return this.request<T>('POST', url, Object.assign({ body: body }, options));
  }

  put<T>(url, body, options): Observable<any> {
    return this.request<T>('PUT', url, Object.assign({ body: body }, options));
  }

  delete<T>(url, options): Observable<any> {
    return this.request<T>('DELETE', url, options);
  }
}
