import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FormHelper } from 'src/app/shared/helpers/forms/form-helper';
import { UserService } from 'src/app/core/services/user/user.service';
import { CustomValidator } from '../../../../helpers/forms/custom-validator';

@Component({
	selector: 'app-changepassword-modal',
	templateUrl: './changepassword-modal.component.html'
})
export class ChangepasswordModalComponent implements OnInit {
	@Input() visible: boolean;
	@Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	changePasswordForm: UntypedFormGroup;
	formHelper: FormHelper;

	constructor(private formBuilder: UntypedFormBuilder, private userService: UserService) {
		this.formHelper = new FormHelper();
		this.changePasswordForm = this.formBuilder.group(
			{
				password: [ '', [ Validators.required, Validators.minLength(8) ] ],
				passwordConfirm: [ '', [ Validators.required ] ]
			},
			{ validator: CustomValidator.Match('password', 'passwordConfirm') }
		);
	}

	ngOnInit() {}

	get f() {
		return this.changePasswordForm.controls;
	}

	close() {
		this.changePasswordForm.reset();
		this.visible = false;
		this.visibleChange.emit(this.visible);
	}

	changePassword() {
		this.userService.changePassword(this.f.password.value);
		this.changePasswordForm.reset();
		this.visible = false;
		this.visibleChange.emit(this.visible);
	}
}
