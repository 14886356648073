import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const Authorization = this.userService.getToken();
    const DataToken = this.userService.getDataToken();
    const Locale = this.userService.getLanguage();

    const newHeader = {
      setHeaders: {
        Authorization: `Bearer ${Authorization}`,
        ...(DataToken && { DataToken }),
        ...(Locale && { Locale }),
      },
    };

    if (Authorization || DataToken || Locale) {
      request = request.clone(newHeader);
    }

    return next.handle(request);
  }
}
