<div class="dropdown lang-selector">
  <a class="nav-link dropdown-toggle text-muted waves-effect waves-dark" href="" data-toggle="dropdown"
    aria-haspopup="true" aria-expanded="false">
    <span id="show-code-language" *ngIf="showCode">
      {{ currentLanguage.code }}
    </span> <em class="flag-icon {{ currentLanguage.icon }}"></em>
    <i class="fa fa-chevron-down" style="color: #020147;"></i>
  </a>
  <div class="dropdown-menu dropdown-menu-right">
    <span *ngFor="let language of getAvailableLanguages()">
      <a class="dropdown-item" (click)="changeLanguage(language.code)">
        <em class="flag-icon {{ language.icon }}"></em>{{ language.name }}
      </a>
    </span>
  </div>
</div>
