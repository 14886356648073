import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AppMenuService } from 'src/app/core/services/config/app-menu.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  public showOnlyMainComponent: boolean;
  public htmlDescription: string;
  private lastUrl: string;
  private menuAlreadyHasData: boolean;
  private menu: any;

  constructor(private route: ActivatedRoute, private appMenuService: AppMenuService, private router: Router) {
    this.showOnlyMainComponent = false;
    this.htmlDescription = null;
    this.lastUrl = null;
    this.menuAlreadyHasData = false;
    this.menu = null;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.lastUrl = event.urlAfterRedirects;
        this.updateHtmlDescription();
      }
    });
  }

  ngOnInit(): void {
    this.showOnlyMainComponent = this.route.snapshot.queryParamMap.get('showOnlyMainComponent')
      ? true
      : false;
    this.appMenuService.menuAlreadyInitialized.getObserver().subscribe(data => {
      if (data) {
        this.menu = data;
        this.menuAlreadyHasData = true;
        this.updateHtmlDescription();
      }
    });
  }

  updateHtmlDescription() {
    if (this.lastUrl && this.menuAlreadyHasData) {
      this.htmlDescription = this.appMenuService.getDescription(this.lastUrl, this.menu);
      this.lastUrl = null;
    }
  }
}
