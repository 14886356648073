import { Injectable, OnDestroy } from '@angular/core';
import { CreateObservable } from './create-observable';
import { CacheService } from './cache/cache.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService implements OnDestroy {
  dataAsObservable: CreateObservable;
  cacheExpiration = 86400;
  breadcrumbObservable: Subject<any>;

  constructor(private cacheService: CacheService) {
    this.dataAsObservable = new CreateObservable();
    this.breadcrumbObservable = new Subject();
    window.addEventListener('beforeunload', this.deleteLastBreadcrumb.bind(this));
  }

  get breadcrumbs() {
    try {
      return this.cacheService.getItem('breadcrumbs')
        ? this.cacheService
            .getItem('breadcrumbs')
            .split(',')
            .filter(str => str != '')
        : null;
    } catch (error) {
      return null;
    }
  }

  getBreadcrumbs() {
    this.breadcrumbs
      ? this.dataAsObservable.next(this.breadcrumbs.join(' > '))
      : this.dataAsObservable.next('');
  }

  addBreadcrumb(newBreadcrumb) {
    const breadcrumbs = this.breadcrumbs;
    if (breadcrumbs && breadcrumbs.length > 0) {
      breadcrumbs.push(newBreadcrumb);
      this.cacheService.setItem('breadcrumbs', breadcrumbs.join(','), false, this.cacheExpiration);
    } else if (newBreadcrumb) {
      this.cacheService.setItem('breadcrumbs', newBreadcrumb, false, this.cacheExpiration);
    }
  }

  addBreadCrumbFromCustomPage(newBreadcrumbs) {
    this.breadcrumbObservable.next(newBreadcrumbs);
  }

  deleteBreadcrumbs() {
    this.cacheService.removeItem('breadcrumbs');
  }

  deleteLastBreadcrumb() {
    const breadcrumbs = this.breadcrumbs;
    if (breadcrumbs && breadcrumbs.length > 0) {
      breadcrumbs.pop();
      this.cacheService.setItem(
        'breadcrumbs',
        breadcrumbs ? breadcrumbs.join(',') : '',
        false,
        this.cacheExpiration
      );
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.deleteLastBreadcrumb.bind(this));
  }
}
