import { FormHelper } from '../form-helper';
import { BaseDbValidator } from './base-db-validator';
import { ValidatorResponse } from './validator.model';

export class DatabaseFieldValidator extends BaseDbValidator {
  validate(): ValidatorResponse | null {
    if (FormHelper.isDirtyAndNotEmpty(this.control) && this.control.value) {
      if (!/^[A-Za-z0-9\-_]*$/.test(this.control.value)) {
        return {
          message: 'onlyLetterAndNumbers',
        };
      }
    }
    return null;
  }
}
