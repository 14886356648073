import { BaseDbValidator } from './base-db-validator';
import { ValidatorOptions } from './validator.model';
import { MaxDbValidator } from './max-db-validator';
import { MinDbValidator } from './min-db-validator';
import { MaxLengthDbValidator } from './max-length-db-validator';
import { MinLengthDbValidator } from './min-length-db-validator';
import { ExpressionDbValidator } from './expression-db-validator';
import { DatabaseFieldValidator } from './database-field-validator';
import { MoreThanOneInAYearValidator } from './more-than-one-in-a-year/more-than-one-in-a-year-validator';

const mappings = {
  min: MinDbValidator,
  max: MaxDbValidator,
  maxlength: MaxLengthDbValidator,
  minlength: MinLengthDbValidator,
  expression: ExpressionDbValidator,
  databasefield: DatabaseFieldValidator,
  moreThanOneInAYear: MoreThanOneInAYearValidator,
};

export class FactoryDbValidator {
  static getValidatorByName(name: string, options: ValidatorOptions): BaseDbValidator {
    if (mappings[name]) {
      return new mappings[name](options);
    } else {
      console.log(`Validator not found: ${name}`);
      throw 'Validator not found';
    }
  }
}
