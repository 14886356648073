<div>
  <a
    class="nav-link dropdown-toggle text-white waves-effect waves-dark"
    href=""
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <i class="mdi mdi-account-circle-outline"></i
  ></a>
  <div class="dropdown-menu dropdown-menu-right scale-up font-montserrat">
    <ul class="dropdown-user">
      <li>
        <div class="dw-user-box">
          <div class="u-text font-montserrat">
            <h4 class="font-montserrat" style="font-weight: 600;">{{ userNameFull }}</h4>
            <p class="text-muted" style="color: #7e7b7b !important; font-weight: 600;">{{ role | translate }}</p>
            <p class="text-muted" style="margin-top: 6px;">{{ email }}</p>
          </div>
        </div>
      </li>
      <li role="separator" class="divider"></li>
      <li>
        <a (click)="changePasswordOpenDialog()"
          ><i class="mdi mdi-account-key font-montserrat"></i>
          {{ 'user_menu.change_password' | translate }}</a
        >
      </li>
      <li>
        <a (click)="logout()"
          ><i class="fa fa-power-off"></i> {{ 'user_menu.logout' | translate }}
          <span class="label label-light-info pull-right version font-montserrat"
            ># {{ version }}</span
          ></a
        >
      </li>
    </ul>
  </div>
  <app-changepassword-modal
    [(visible)]="modalVisible"
  ></app-changepassword-modal>
</div>
