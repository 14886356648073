import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppMenuService } from 'src/app/core/services/config/app-menu.service';
import { InitialRouteService } from 'src/app/core/services/config/initial-route.service';
import { IBreadcrumb } from './breadcrumb.model';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styles: [],
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadcrumb[];
  public initalRoute: string;
  public show: boolean;
  private ROUTE_DATA_BREADCRUMB = 'breadcrumb';

  @Input() htmlDescription: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private appMenuService: AppMenuService,
    private initialRouteService: InitialRouteService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbs = [];
    this.show = false;
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.show = false;
        this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root);
        this.show = true;
      }
    });

    this.appMenuService.dataObserver.subscribe(data => {
      this.show = false;
      this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root);
      this.show = true;
    });
    this.appMenuService.getData();

    this.initialRouteService.dataObserver.subscribe(data => {
      this.show = false;
      this.initalRoute = data.path;
      this.show = true;
    });
    this.initialRouteService.getData();
    this.breadcrumbService.breadcrumbObservable.subscribe(data => {
      this.breadcrumbs = data;
    });
  }

  private getBreadcrumbs(route: ActivatedRoute, url = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      if (!child.snapshot.data.hasOwnProperty(this.ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      url += `/${routeURL}`;
      const breadcrumb: IBreadcrumb = {
        name: child.snapshot.data['name'],
        label: child.snapshot.data['title']
          ? this.translate.instant(`breadcrumb.${child.snapshot.data['title']}`)
          : this.appMenuService.getMenuLabel(
              child.snapshot.data['name'],
              child.snapshot.data[this.ROUTE_DATA_BREADCRUMB]
            ),
        params: child.snapshot.params,
        url: url,
      };

      breadcrumbs.push(breadcrumb);
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

  getLastLabel(): string {
    let title = 'Home';
    if (this.breadcrumbs.length >= 2) {
      title = this.breadcrumbs[this.breadcrumbs.length - 2].label;
    }
    return title;
  }
}
