import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './shared/layout/layout.component';
import { NopagefoundComponent } from './shared/components/nopagefound/nopagefound.component';
import { LoginGuardService } from './core/services/guard/login-guard.service';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [LoginGuardService],
    loadChildren: () => import('./modules/base.module').then(m => m.BaseModule),
  },
  { path: '**', component: NopagefoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { canceledNavigationResolution: 'computed' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
