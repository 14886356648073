<ng-container *ngIf="!showOnlyMainComponent">
  <div class="page-wrapper">
    <div class="container-fluid">
      <div id="content">
        <div class="row page-titles">
          <div class="col-md-12 col-12 align-self-center">
            <app-breadcrumb [htmlDescription]="htmlDescription"></app-breadcrumb>
          </div>
          <!-- <div class="col-md-5 col-5 align-self-center">
            <app-status></app-status>
          </div> -->
        </div>
        <div id="main-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div style="margin-top: -73px;" *ngIf="showOnlyMainComponent">
  <router-outlet></router-outlet>
</div>

<ngx-spinner bdColor="rgba(150, 150, 150, 0.6)"
  template="<div class='preloader'><svg class='circular' viewBox='25 25 50 50'><circle class='path' cx='50' cy='50' r='20' fill='none' stroke-width='2' stroke-miterlimit='10' /></svg></div>">
</ngx-spinner>