import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OptionsService } from 'src/app/core/services/api-base.service';
import { DataService } from 'src/app/core/services/data.service';

type MoreThanInAYearOptions = OptionsService & {
  service?: string;
  dateField?: string;
  type?: 'warning';
  fieldToQuery?: string;
  message?: { label: string };
};

@Injectable({
  providedIn: 'root',
})
export class MoreThanOneInAYearValidatorService extends DataService {
  dateField: string;
  service: string;
  fieldToQuery: string;
  filters: { [x: string]: { value: any; operator: string } };

  init(options: MoreThanInAYearOptions = this.options): void {
    super.init(options);

    this.dateField = options.dateField;
    this.service = options.service;
    this.fieldToQuery = options.fieldToQuery;
    this.filters = {
      [this.fieldToQuery]: { value: null, operator: '=' },
    };
  }

  getDuplicated(value: any): Promise<any> {
    this.filters[this.fieldToQuery].value = `${value}`;
    let params = new HttpParams();
    params = params.append('filters', JSON.stringify(this.filters));

    return new Promise(resolve => {
      this.get<any>(`${this.baseUrl}/${this.service}`, { params }).subscribe(data => {
        if (data) {
          resolve(data);
        }
      });
    });
  }
}
