<div *ngIf="visible" class="modal fade show" id="change-password-modal" tabindex="-1" role="dialog"
  style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"> {{ 'change_password_modal.title' | translate }}</h4>
        <button type="button" (click)="close()" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="changePasswordForm" method="post" id="changepassword-form" (ngSubmit)="changePassword()">
          <div class="form-group">
            <div class="col-xs-12">
              <label for="password"
                class="control-label">{{ 'change_password_modal.password.label' |translate }}</label>
              <input formControlName="password" type="password" name="password" class="form-control"
                placeholder="{{ 'change_password_modal.password.placeholder' | translate }}" required minlength="8"
                [ngClass]="{'is-invalid':  formHelper.invalidControlForm(f.password)}">
            </div>
            <span class="invalid-feedback d-block"
              *ngIf="f.password.errors?.required && formHelper.dirtyOrToucheControlForm(f.password)">
              <span>
                {{ 'change_password_modal.password.required.error' | translate }}
              </span>
            </span>
            <span class="invalid-feedback d-block"
              *ngIf="f.password.errors?.minlength && formHelper.dirtyOrToucheControlForm(f.password)">
              <span>
                {{ 'change_password_modal.password.minLength.error' | translate:{'numero':f.password.errors.minlength.requiredLength} }}
              </span>
            </span>
          </div>

          <div class="form-group">
            <div class="col-xs-12">
              <label for="passwordConfirm"
                class="control-label">{{ 'change_password_modal.passwordConfirm.label'| translate }}
              </label>
              <input formControlName="passwordConfirm" type="password" name="passwordConfirm" class="form-control"
                placeholder="{{ 'change_password_modal.passwordConfirm.placeholder' | translate }}" required
                [ngClass]="{'is-invalid':  formHelper.invalidControlForm(f.passwordConfirm)}">
            </div>
            <span class="invalid-feedback d-block"
              *ngIf="f.passwordConfirm.errors?.required && formHelper.dirtyOrToucheControlForm(f.passwordConfirm)">
              <span>
                {{ 'change_password_modal.passwordConfirm.required.error' | translate }}
              </span>
            </span>
            <span class="invalid-feedback d-block"
              *ngIf="f.passwordConfirm.errors?.matchfield && formHelper.dirtyOrToucheControlForm(f.passwordConfirm)">
              <span>
                {{ 'change_password_modal.passwordConfirm.matchfield.error' | translate }}
              </span>
            </span>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="close()" class="btn"
          data-dismiss="modal">{{ 'change_password_modal.button.close' | translate }} </button>
        <button type="button" [disabled]="!changePasswordForm.valid" (click)="changePassword()" class="btn btn-dark"
          data-action="submit">{{ 'change_password_modal.button.change' | translate }}</button>
      </div>
    </div>
  </div>
</div>
