import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user/user.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AppInitService {
  constructor(
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  init(): Promise<void> {
    return new Promise<void>(async resolve => {
      const url = new URL(window.location.href);
      const token = url.searchParams.get('challengeToken');
      const accountId = url.searchParams.get('accountId');
      const locale = url.searchParams.get('locale');
      const switchAccount = url.searchParams.get('switchAccount');
      const switchCatalog = url.searchParams.get('switchCatalog');
      const mainComponentUrl = url.searchParams.get('mainComponentUrl');
      const encodedLogoUrl = url.searchParams.get('logo_url');
      const logoUrl = encodedLogoUrl ? atob(encodedLogoUrl) : null;
      const tokenType = url.searchParams.get('tokenType');
      const record = url.searchParams.get('record');

      if (token && accountId && locale) {
        await this.userService.getUserDataPromise({
          token,
          accountId,
          locale,
          logoUrl,
          tokenType,
        });

        const user = this.userService.getUser();

        if (user.lastLogin && !switchAccount && !switchCatalog) {
          const lastLogin = new Date(user.lastLogin).toLocaleString(locale);
          setTimeout(() => {
            this.notificationService.showLastLogin(
              this.translate.instant('common.lastLogin', {
                name: user.name,
                lastLogin,
              })
            );
          }, 1000);
        }

        if (switchAccount) {
          this.router.navigate([`/dashboards/list`]);
        } else if (mainComponentUrl) {
          const route = record ? `${mainComponentUrl}/details/${record}` : `${mainComponentUrl}`;
          this.router.navigateByUrl(route);
        } else {
          this.router.navigate(['/dashboards/list']);
        }

        resolve();
      } else {
        resolve();
      }
    });
  }
}
