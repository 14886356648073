import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserService } from './core/services/user/user.service';
import { HttpClientModule } from '@angular/common/http';
import { NopagefoundModule } from './shared/components/nopagefound/nopagefound.module';

// User modules
import { AppComponent } from './app.component';
import { LayoutModule } from './shared/layout/layout.module';
import { CoreModule } from './core/core.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppInitService } from './core/services/app-init.service';
import { ServiceLocator } from './core/services/locator.service';
import { TranslateService } from '@ngx-translate/core';

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    LayoutModule,
    CoreModule,
    NopagefoundModule,
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    UserService,
    TranslateService,
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: translateService => translateService.currentLang,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
