import { AbstractControl } from '@angular/forms';
import { Validator, ValidatorOptions, ValidatorResponse } from './validator.model';

export class BaseDbValidator {
  protected control: AbstractControl;
  protected validator: Validator;
  protected params: any;

  constructor(options: ValidatorOptions) {
    this.control = options.control;
    this.validator = options.validator;
    this.params = { value: this.validator.value };
  }

  validate(): ValidatorResponse | null | Promise<ValidatorResponse> {
    throw 'Implemenent in childs class';
  }
}
