import { FormHelper } from './../../form-helper';
import { AbstractControl } from '@angular/forms';
import { ServiceLocator } from '../../../../../core/services/locator.service';
import { BaseDbValidator } from '../base-db-validator';
import { ValidatorOptions, ValidatorResponse } from '../validator.model';
import { MoreThanOneInAYearValidatorService } from './services/more-than-one-in-a-year-validator.service';

export class MoreThanOneInAYearValidator extends BaseDbValidator {
  protected form: AbstractControl;
  protected operator: string;
  protected isBeingCalled: boolean;
  protected _service: MoreThanOneInAYearValidatorService;
  protected type: string;
  today: Date;
  todayMinusOneYear: Date;
  characterAmountBeforeApiCall: number;

  constructor(options: ValidatorOptions) {
    super(options);

    this.characterAmountBeforeApiCall = this.validator.parameters.characterAmountBeforeApiCall;
    this._service = ServiceLocator.injector.get(MoreThanOneInAYearValidatorService);
    this.isBeingCalled = false;

    this.today = new Date();
    this.todayMinusOneYear = new Date(new Date().setFullYear(this.today.getFullYear() - 1));

    this.type = this.validator.parameters.type === 'warning' ? 'WarningValidator' : 'DatabaseValidator';
    if (this.control.parent) {
      const controlName = FormHelper.getControlName(this.control);
      this._service.init({
        entityName: this.validator.parameters.service,
        fieldToQuery: controlName,
        ...this.validator.parameters,
      });
    }
  }

  setError(): void {
    this.control.setErrors({
      [this.type]: { message: this.validator.parameters.message.label },
    });
  }

  getIfHasMoreOfOneInAYear(items: any[]): boolean {
    const { dateField } = this.validator.parameters;
    let amountOfTimesInAYear = 0;
    items.forEach(item => {
      const dateValue = new Date(item[dateField]);
      if (dateValue >= this.todayMinusOneYear && dateValue <= this.today) {
        amountOfTimesInAYear++;
      }
    });
    return amountOfTimesInAYear > 0;
  }

  isCallToEndpointIsEnabled(value: any): boolean {
    if (!this.control.dirty || !value || this.isBeingCalled) {
      return false;
    }

    if (value.toString().length < this.characterAmountBeforeApiCall) {
      return false;
    }

    return true;
  }

  validate(): ValidatorResponse {
    const { value } = this.control;

    if (!this.isCallToEndpointIsEnabled(value)) {
      return;
    }

    if (!this.isBeingCalled) {
      this._service
        .getDuplicated(value)
        .then(({ items }) => {
          const hasMoreOfOneInAYear = this.getIfHasMoreOfOneInAYear(items);
          if (hasMoreOfOneInAYear) {
            this.setError();
          }
        })
        .finally(() => {
          this.isBeingCalled = false;
        });
    }

    return null;
  }
}
