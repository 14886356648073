<section id="wrapper" class="error-page">
  <div>
    <div class="error-body text-center">
      <img style="width: 47%;" src="assets/images/notfound.svg" alt="notfound">
      <p style="color: #3F4254; font-family: 'Open Sans'; font-weight: 600; font-size: 20px; margin-top: 3rem;">{{ '404error' | translate }}</p>
      <a href="/" style="background-color: #020147; border-radius: 6px; padding: 10px 20px" class="waves-effect waves-light">
        <span style="color: white; font-family: 'Open Sans'; font-weight: 600;">{{ 'BackToTop' | translate }}</span>
      </a>
    </div>
  </div>
</section>
