import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private callers: number[];
  private _isShow: boolean;
  private count = 0;

  constructor(private spinner: NgxSpinnerService) {
    this._isShow = false;
    this.callers = [];
  }

  show(): void {
    if (!this._isShow) {
      this._isShow = true;
      this.spinner.show();
    }
    this.count++;
    this.callers.push(this.count);
  }

  hide(): void {
    if (this._isShow && this.callers.length === 1) {
      this.spinner.hide();
      this._isShow = false;
      this.count = 0;
    }
    if (this.callers.length > 0) {
      this.callers.pop();
      this.count--;
      if (this.count < 1) {
        this.count = 0;
      }
    }
  }

  isShow(): boolean {
    return this._isShow;
  }
}
