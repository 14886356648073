import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../core/services/user/user.service';
import { MenuStatus, UserSettings } from '../../../core/models/user-settings.model';
import { AppMenuService } from '../../../core/services/config/app-menu.service';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Catalog } from 'src/app/core/models/catalog.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [],
})
export class HeaderComponent implements OnInit {
  accountName: string;
  showMenu: boolean;
  selectAccountEnabled: boolean;
  optionsSelect: Array<Select2OptionData>;
  optionsConfig: Options;
  remoteOptions: Options;
  logoUrl: string;
  catalogName: string;
  selectCatalogEnabled: boolean;
  catalogOptions: Options;
  accountCatalogs: Catalog[];
  showOnlyMainComponent: boolean;
  hideHeader: boolean;

  constructor(
    public userService: UserService,
    private appMenuService: AppMenuService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    document.title = this.translate.instant('common.title');
    this.showMenu = false;
    this.showOnlyMainComponent = false;
    this.hideHeader = false;
    this.logoUrl = this.userService.getLogoUrl();
    this.accountCatalogs = this.userService.getCatalogs();
    this.optionsConfig = {
      minimumResultsForSearch: -1,
      width: 'auto',
    };

    this.remoteOptions = {
      width: '250px',
      allowClear: true,
      placeholder: {
        id: '',
        placeholder: this.translate.instant('Search Account...'),
      },
      language: {
        searching: () => this.translate.instant('Searching...'),
        loadingMore: () => this.translate.instant('Loading more...'),
      },
      ajax: {
        url: `${environment.root_api}/accounts`,
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json, text/plain, */*',
          Authorization: `Bearer ${this.userService.getToken()}`,
          DataToken: this.userService.getDataToken(),
          Locale: this.userService.getLanguage(),
        },
        data: params => ({
          q: params.term,
          p: params.page || 1,
          pp: 100000,
          _type: 'query_append',
        }),
        processResults: (data, params) => ({
          results: data.items.map(account => ({
            id: `${account.id}`,
            text: `${account.accountname}`,
          })),
          pagination: {
            more: data.items.length > 0,
          },
        }),
        cache: true,
      },
      escapeMarkup: markup => markup,
      templateResult: d => d.text,
      templateSelection: d =>
        d.id == null || d.id == '' || d.id == undefined
          ? this.translate.instant('Search Account...')
          : d.text,
    };

    this.catalogOptions = {
      width: '250px',
      allowClear: true,
      placeholder: {
        id: '',
        placeholder: this.translate.instant('Search Catalog...'),
      },
      language: {
        searching: () => this.translate.instant('Searching...'),
        loadingMore: () => this.translate.instant('Loading more...'),
      },
      ajax: {
        url: `${environment.root_api}/common/catalogs`,
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json, text/plain, */*',
          Authorization: `Bearer ${this.userService.getToken()}`,
          DataToken: this.userService.getDataToken(),
          Locale: this.userService.getLanguage(),
        },
        data: params => ({
          q: params.term,
        }),
        processResults: data => ({
          results: data
            .filter(catalog => this.accountCatalogs.map(x => x.id).includes(catalog.id))
            .map(catalog => ({
              id: `${catalog.id}`,
              text: `${catalog.name}`,
            })),
        }),
        cache: true,
      },
      escapeMarkup: markup => markup,
      templateResult: d => d.text,
      templateSelection: d =>
        d.id == null || d.id == '' || d.id == undefined
          ? this.translate.instant('Search Catalog...')
          : d.text,
    };

    this.optionsSelect = [];
  }

  ngOnInit(): void {
    this.showOnlyMainComponent = this.route.snapshot.queryParamMap.get('showOnlyMainComponent')
      ? true
      : false;
    this.hideHeader = this.route.snapshot.queryParamMap.get('hideHeader') == 'true' ? true : false;
    if (!this.showOnlyMainComponent) {
      this.accountName = this.userService.getAccount()?.name;
      this.catalogName = this.userService.getDefaultCatalog()?.name;

      this.appMenuService.init();

      const bodyEl = document.querySelector('body');
      const navbarLogo = document.querySelector('.navbar .navbar-brand span') as HTMLElement;
      const navbarIsotipoLogo = document.querySelector('.navbar .navbar-brand strong') as HTMLElement;

      if (this.userService.settings?.statusMenu == MenuStatus.EXPAND) {
        bodyEl.classList.remove('mini-sidebar');
        //navbarLogo.style.display = 'inline';
        //navbarIsotipoLogo.style.display = 'none';
      } else {
        bodyEl.classList.add('mini-sidebar');
        if (navbarLogo) {
          navbarLogo.style.display = 'none';
        }
      }

      this.appMenuService.dataObserver.subscribe(data => {
        this.showMenu = data ? data.some(menu => menu.show) : false;
      });

      this.appMenuService.getData();

      this.userService.tokenObserver.subscribe(data => {
        window.location.reload();
      });
    }
  }

  toggleStatusMenu(): void {
    this.userService.settings = {
      statusMenu: document.querySelector('body').classList.contains('mini-sidebar')
        ? MenuStatus.EXPAND
        : MenuStatus.CONTRACT,
    } as UserSettings;
  }

  switchAccount(accountId: string): void {
    this.selectAccountEnabled = false;
    this.userService.switchAccount(accountId);
  }

  switchCatalog(catalogId: string): void {
    this.selectCatalogEnabled = false;
    this.userService.switchCatalog(catalogId);
  }
}
