import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/core/services/api-base.service';

@Injectable({
  providedIn: 'root',
})
export class DataService extends ApiBaseService {
  getRecord<T>(): void {
    this.get<T>(`${this.url}/${this.id}`).subscribe(data => {
      if (data) {
        this.data = data;
        this.dataSubject.next(Object.assign({}, this.data));
      }
    });
  }

  async getSyncRecord(): Promise<any> {
    const data = await this.get<any>(`${this.url}/${this.id}`).toPromise();
    return data;
  }

  getData<T>(): void {
    this.get<T>(this.url).subscribe(data => {
      if (data) {
        this.data = data;
        this.dataSubject.next(this.data);
      }
    });
  }

  create(record: any): Promise<void> {
    return new Promise(resolve => {
      const httpOptions = this.getHttpOptions(record.constructor.name === 'FormData');
      this.post<any>(this.url, record, httpOptions).subscribe(response => resolve(response));
    });
  }

  update(record: any): Promise<void> {
    return new Promise(resolve => {
      const httpOptions = this.getHttpOptions(record.constructor.name === 'FormData');
      this.put<any>(`${this.url}/${this.id}`, record, httpOptions).subscribe(response => resolve(response));
    });
  }
}
