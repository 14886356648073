import { Observable, BehaviorSubject } from 'rxjs';

export class CreateObservable {
  private observer: Observable<any>;
  private dataSubject: BehaviorSubject<any>;

  constructor(data: string = null) {
    this.dataSubject = new BehaviorSubject<any>(data);
    this.observer = this.dataSubject.asObservable();
  }

  getObserver(): Observable<any> {
    return this.observer;
  }

  next(data: any): void {
    this.dataSubject.next(data);
  }
}
