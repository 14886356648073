import { Injectable } from '@angular/core';
import { OptionsService } from '../api-base.service';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root',
})
export class InitialRouteService extends DataService {
  protected options: OptionsService = {
    route: 'common/initial_route',
    entityName: 'initial_route',
    cache: { enabled: true },
  };
}
