import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuardService implements CanActivate {
  constructor(public userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return route.routeConfig.path === '' || this.userService.isLogged();
  }
}
