import { Observable, Subject } from 'rxjs';

export class CurrentRequest {
  url: string;
  private observer: Observable<any>;
  private dataSubject: Subject<any>;

  constructor(url: string) {
    this.url = url;
    this.dataSubject = new Subject<any>();
    this.observer = this.dataSubject.asObservable();
  }

  getObserver(): Observable<any> {
    return this.observer;
  }

  next(data: any): void {
    this.dataSubject.next(data);
  }
}

export class CurrentRequests {
  arrayCurrentRequest: CurrentRequest[] = [];

  add(url: string): void {
    this.arrayCurrentRequest.push(new CurrentRequest(url));
  }

  get(url: string): CurrentRequest {
    return this.arrayCurrentRequest.find(request => request.url === url);
  }

  remove(url: string): void {
    this.arrayCurrentRequest = this.arrayCurrentRequest.filter(request => request.url !== url);
  }
}
