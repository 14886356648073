import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  logError(error: Error): void {
    console.log('LoggingService', error);
  }
}
