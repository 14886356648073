import { AbstractControl } from '@angular/forms';
import { FormHelper } from '../form-helper';
import { BaseDbValidator } from './base-db-validator';
import { ValidatorOptions, ValidatorResponse } from './validator.model';

export class ExpressionDbValidator extends BaseDbValidator {
  protected form: AbstractControl;
  protected operator: string;
  protected leftExpression: any;
  protected rightExpression: any;

  constructor(options: ValidatorOptions) {
    super(options);
    this.form = options.control;
    this.operator = options.validator.parameters.operator;
    this.leftExpression = options.validator.parameters.leftExpression;
    this.rightExpression = options.validator.parameters.rightExpression;
  }

  validate(): ValidatorResponse | null {
    if (this.form.dirty && this.isDirtyAndNotEmpty()) {
      const leftExpression = this.expressionToString(this.leftExpression);
      const rightExpression = this.expressionToString(this.rightExpression);
      try {
        const result = eval(leftExpression + ' ' + this.operator + ' ' + rightExpression);
        if (!result) {
          return {
            message: this.validator.parameters.message.label,
          };
        }
      } catch (e) {
        return {
          message: this.validator.parameters.message.label,
        };
      }
    }
    return null;
  }

  isDirtyAndNotEmpty(): boolean {
    for (const expression of this.leftExpression) {
      const control = this.form.get(expression.fieldName);
      if (!control) {
        return false;
      }
      if (FormHelper.isDirtyAndNotEmpty(control)) {
        return true;
      }
    }
    for (const expression of this.rightExpression) {
      const control = this.form.get(expression.fieldName);
      if (!control) {
        return false;
      }
      if (FormHelper.isDirtyAndNotEmpty(control)) {
        return true;
      }
    }
    return false;
  }

  expressionToString(expressions: any): string {
    let result = '';
    for (const expression of expressions) {
      const fieldName = expression.fieldName;
      const leftOperator = expression.leftOperator || '';
      const rightOperator = expression.rightOperator || '';
      if (!this.control.get(fieldName)) {
        return '';
      }
      const value = this.control.get(fieldName).value;
      result = `${result} ${(leftOperator + ' ' + value + ' ' + rightOperator).trim()}`;
    }
    return result;
  }
}
