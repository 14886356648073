<aside
  *ngIf="showMenu"
  class="left-sidebar"
  #elementRef
  (mouseenter)="onMouseOver()"
  (mouseleave)="onMouseOut()"
  style="padding: 0 !important"
>
  <div class="sidebar-top-logo">
    <img
      *ngIf="currentLogo"
      class="sidebar-top-img"
      [src]="currentLogo"
      alt="logo"
    />
  </div>
  <div class="scroll-sidebar" style="padding-top: 113px">
    <nav class="sidebar-nav">
      <ul id="sidebarnav">
        <ng-container *ngFor="let menu of appMenu">
          <ng-container *ngIf="menu.show">
            <li
              class="nav-small-cap"
              style="
                background-color: initial;
                font-weight: 600 !important;
                color: #181c32 !important;
                font-size: 14px !important;
                text-transform: none;
              "
            >
              {{ menu.label }}
            </li>
            <li *ngFor="let item of menu.entries" [ngClass]="{ active: item.active }">
              <a
                (click)="itemClicked($event, item)"
                *ngIf="item.childs?.length == 0"
                class="parent"
                [routerLink]="[item.link]"
                aria-expanded="false"
              >
                <i class="{{ item.icon }} menu-icon"></i>
                <span class="hide-menu menu-label">{{ item.label }}</span>
              </a>
              <a
                (click)="itemClicked($event, item)"
                *ngIf="item.childs?.length > 0"
                class="parent"
                [ngClass]="{ 'has-arrow': item.childs.length > 0 }"
                aria-expanded="false"
              >
                <i class="{{ item.icon }} menu-icon"></i>
                <span class="hide-menu menu-label">{{ item.label }}</span>
              </a>
              <ul
                *ngIf="item.childs?.length > 0 && item.active"
                aria-expanded="false"
                class="in sidebar-child"
              >
                <li *ngFor="let child of item.childs">
                  <a
                    (click)="childItemClicked($event, item, child)"
                    class="child"
                    [routerLink]="[child.link]"
                    [ngClass]="{ childActive: child.active }"
                    >{{ child.label }}</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-separator"></li>
          </ng-container>
        </ng-container>
        <li class="logo-container" *ngIf="!isFromSirena">
          <img src="assets/images/gc-logo.svg" alt="logo" />
        </li>
      </ul>
    </nav>
  </div>
</aside>
