import { Subject, Observable, ReplaySubject } from 'rxjs';

export class ObserverBehavior {
  private subject: Subject<any>;
  private observer: Observable<any>;

  constructor() {
    this.subject = new ReplaySubject(1);
    this.observer = this.subject.asObservable();
  }

  getObserver(): Observable<any> {
    return this.observer;
  }

  next(data: any): void {
    this.subject.next(data);
  }
}
