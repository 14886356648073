import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlerModule } from './errors/error-handler.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpResponseInterceptor } from './interceptors/http-response.interceptor';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule, ErrorHandlerModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
  ],
})
export class CoreModule {}
