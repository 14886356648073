  <div class="breadcrumb-container">
  <h3 *ngIf="show" class="text-themecolor m-b-0 m-t-0">{{ getLastLabel() | translate }}</h3>
  <nav aria-label='breadcrumb'>
    <ol class='breadcrumb'>
      <li class="breadcrumb-item"><a [routerLink]="[initalRoute]">{{ 'breadcrumb.Home' | translate }}</a> </li>
      <li [ngClass]="{'breadcrumb-item': true, 'active': isLast}"
        *ngFor='let breadcrumb of breadcrumbs; last as isLast'>
        <a *ngIf="!isLast" [routerLink]='[breadcrumb.url ?? breadcrumb.link]'>{{ breadcrumb.label | translate }}</a>
        <span *ngIf="isLast">{{ breadcrumb.label | translate }}</span>
      </li>
    </ol>
  </nav>
  <div *ngIf="htmlDescription" class="html_description" [innerHTML]="htmlDescription | safeHtml"></div>
</div>