import { FormHelper } from '../form-helper';
import { BaseDbValidator } from './base-db-validator';
import { ValidatorResponse } from './validator.model';

export class MaxDbValidator extends BaseDbValidator {
  validate(): ValidatorResponse {
    if (FormHelper.isDirtyAndNotEmpty(this.control) && this.control.value > this.validator.value) {
      return {
        message: 'The value can not be greater than value',
        params: this.params,
      };
    }
    return null;
  }
}
