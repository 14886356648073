import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventBusService, Events } from '../event-bus/event-bus.service';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  preffix: string;
  eventBusSub: Subscription;

  constructor(private eventBus: EventBusService) {
    this.eventBusSub = this.eventBus.on(Events.CachePreffix, preffix => {
      this.preffix = preffix;
      localStorage.setItem(environment.cache.default_preffix, this.preffix);
    });

    const defaultPreffix = localStorage.getItem(environment.cache.default_preffix);
    this.preffix = defaultPreffix ? defaultPreffix : 'gandalf';
  }

  public getItem(label: string, isLocalePreffix: boolean = false): any {
    const data = JSON.parse(localStorage.getItem(this.getLabel(label, isLocalePreffix)));
    if (data && data.valid >= new Date().getTime()) {
      return data.value;
    } else {
      localStorage.removeItem(`${this.preffix}:${label}`);
      return null;
    }
  }

  public setItem(label: string, value: any, isLocalePreffix: boolean = false, expiration?: number): void {
    localStorage.setItem(
      this.getLabel(label, isLocalePreffix),
      JSON.stringify({
        value: value,
        valid: new Date().getTime() + (expiration ? expiration : environment.cache.expiration_time),
      })
    );
  }

  public removeItem(label: string, isLocalePreffix: boolean = false): void {
    localStorage.removeItem(this.getLabel(label, isLocalePreffix));
  }

  public clearAll(): void {
    localStorage.clear();
  }

  public clearByPreffix(preffix: string): void {
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).indexOf(`${preffix}:`) == 0) {
        localStorage.removeItem(localStorage.key(i));
      }
    }
  }

  private getLabel(label, isLocalePrefix) {
    return isLocalePrefix ? `${this.preffix}:${this.siteId}:${this.locale}:${label}` : `${this.preffix}:${label}`;
  }

  private get locale() {
    const data = JSON.parse(localStorage.getItem(`${this.preffix}:language`));
    return data ? data.value : 'en';
  }

  private get siteId() {
    const data = JSON.parse(localStorage.getItem(`${this.preffix}:account`));
    return data ? data.value.site_id : '';
  }
}
