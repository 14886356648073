import { Base } from './base.model';

export enum MenuStatus {
  CONTRACT = 'contracted',
  EXPAND = 'expanded',
}

export type UserFetchingOptions = {
  query?: string;
  records_number_to_display?: number;
  current_page?: number;
  filters?: any;
  sorts?: any;
  checked_items?: any[];
};

export type DynamicFormOptions = {
  lastTabClicked?: string;
};

export type DynamicFormOptionsArray = {
  [key: string]: DynamicFormOptions;
};

export const DefaultDynamicFormOptions: DynamicFormOptions = {
  lastTabClicked: null,
};

const defaultMenuStatus = MenuStatus.EXPAND;
export class UserSettings extends Base {
  public statusMenu: MenuStatus;
  public dynamicList: UserFetchingOptions;
  public dynamicForm: DynamicFormOptionsArray;
  public wizardStep: number;

  constructor(status?: MenuStatus, dynamicList?: UserFetchingOptions) {
    super();

    this.statusMenu = status || defaultMenuStatus;
    this.dynamicList = dynamicList || null;
    this.dynamicForm = {};
    this.wizardStep = 0;
  }
}
