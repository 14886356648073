import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../core/services/user/user.service';

@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styles: [],
})
export class UsermenuComponent implements OnInit {
  modalVisible = false;
  userNameFull: string;
  role: string;
  version: string;
  email: string;
  constructor(private userService: UserService) {}

  ngOnInit(): void {
    const user = this.userService.getUser();
    this.userNameFull = user?.name;
    this.role = user?.role;
    this.email = user?.username;
    this.version = this.userService.getVersion();
  }

  logout(): void {
    this.userService.logout();
  }

  changePasswordOpenDialog(): void {
    this.modalVisible = true;
  }

  onVisibleChange(visible: boolean): void {
    return null;
  }

  submitChangePassword(): void {
    return null;
  }
}
