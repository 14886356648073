import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/core/services/user/user.service';
import { environment } from 'src/environments/environment';
import { Language } from './language.model';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styles: [],
})
export class LanguageComponent implements OnInit {
  public currentLanguage: Language = null;
  public languages: Language[] = null;
  @Input() showCode: boolean;

  constructor(private userService: UserService, public translate: TranslateService) {
    this.languages = environment.language.languages;
  }

  ngOnInit() {
    if (this.userService.getLanguage() != null && this.userService.getLanguage() != 'null') {
      this.currentLanguage = this.languages.find(x => x.code === this.userService.getLanguage());
    } else {
      this.currentLanguage = this.defaultLanguage();
    }
    this.translate.setDefaultLang(this.currentLanguage.code);
  }

  changeLanguage(code: string): void {
    this.currentLanguage = this.languages.find(x => x.code === code);
    this.userService.setLanguage(this.currentLanguage.code);
    this.translate.setDefaultLang(this.currentLanguage.code);
    window.location.reload();
  }

  getAvailableLanguages(): Language[] {
    return this.languages.filter(x => x.code !== this.currentLanguage.code);
  }

  private defaultLanguage(): Language {
    return this.languages.find(x => x.code === environment.language.default);
  }
}
