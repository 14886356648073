import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from 'src/app/core/services/api-base.service';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserDataService extends ApiBaseService {
  getUserData(userData: any): Observable<any> {
    const httpOptions = this.getHttpOptions(true);
    return this.post<any>(this.url, { userData }, httpOptions);
  }

  getUsersByIds(ids: string[]): Observable<User[]> {
    const httpOptions = this.getHttpOptions();
    const url = `${this.baseUrl}/users/get_user_by_ids`;
    return this.post<any>(url, JSON.stringify({ ids: ids }), httpOptions);
  }
}
