import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppMenuService } from 'src/app/core/services/config/app-menu.service';
import { DataService } from 'src/app/core/services/data.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { environment } from 'src/environments/environment';
import { Role } from './../../../../modules/roles/models/role.model';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styles: [],
})
export class AppMenuComponent implements OnInit {
  private subscriptionsRef: Subscription[];
  appMenu: any;
  showMenu: boolean;
  accountId;

  currentLogo;
  hadMiniSidebar;
  isFromSirena;

  constructor(
    private renderer: Renderer2,
    public appMenuService: AppMenuService,
    private router: Router,
    public userService: UserService,
    public dataService: DataService
  ) {
    this.showMenu = false;
    this.subscriptionsRef = [];
    if (this.userService.getUser().role === 'sirena_user') {
      this.isFromSirena = true;
    } else {
      this.isFromSirena = false;
    }
  }

  async ngOnInit() {
    this.appMenuService.dataObserver.subscribe(data => {
      if (data) {
        this.appMenu = data;
        this.showMenu = data ? data.some(menu => menu.show) : false;
        if (!this.showMenu) {
          this.renderer.addClass(document.body, 'single-column');
        }
        this.initAppMenu();
      }
    });

    this.subscriptionsRef.push(
      this.appMenuService.outsideItemMenuHandler.subscribe(entryName => {
        this.activateItemByName(entryName);
      })
    );

    this.appMenuService.getData();

    this.accountId = this.userService.getAccount()?.id;
    await this.getAccountLogo();
  }

  updateActiveMenuItem(currentUrl: string): void {
    this.appMenu[0].entries.forEach(item => {
      if (item.childs.length === 0) {
        item.active = currentUrl === item.link;
      } else {
        item.active = item.childs.some(child => currentUrl === child.link);
        item.childs.forEach(child => {
          child.active = currentUrl === child.link;
        });
      }
    });
  }

  async getAccountLogo() {
    try {
      const account: any = await this.dataService
        .get(`${environment.aragorn_url}/api/accounts/${this.accountId}`)
        .toPromise();
      if (account.logo) {
        const logoData = account.logo;
        const blob = new Blob([new Uint8Array(logoData.content.data)], { type: logoData.type });
        this.currentLogo = URL.createObjectURL(blob);
      } else {
        this.currentLogo = 'assets/images/gc-logo.svg';
        return;
      }
    } catch (error) {
      this.currentLogo = 'assets/images/gc-logo.svg';
      console.error('Error fetching account', error);
    }
  }

  initAppMenu(): void {
    const path = this.router.url.split('/');
    this.appMenu.forEach(menu => {
      menu.entries?.forEach(entry => {
        entry.active = entry.name === path[1];
        entry.childs?.forEach(child => (child.active = child.name === path[2]));
      });
    });
  }

  activateItemByName(entryName) {
    let foundedEntry = null;
    this.appMenu.forEach(menu => {
      menu.entries.forEach(entry => {
        if (entry.name !== entryName) {
          entry.active = false;
          entry.childs?.forEach(child => {
            if (child.name === entryName) {
              foundedEntry = child;
              entry.active = true;
            }
            child.active = false;
          });
        } else {
          foundedEntry = entry;
        }
      });
    });

    if (foundedEntry) {
      foundedEntry.active = true;
    }
  }

  itemClicked(event: Event, item: any): void {
    this.appMenu.forEach(menu => {
      menu.entries.forEach(entry => {
        if (entry.name !== item.name) {
          entry.active = false;
          entry.childs?.forEach(child => (child.active = false));
        }
      });
    });

    item.active = item.childs?.length > 0 ? !item.active : true;
  }

  childItemClicked(event: Event, item: any, child: any): void {
    if (!child.active) {
      item.childs?.forEach(itemChild => (itemChild.active = false));
      child.active = true;
    }
  }

  onMouseOver(): void {
    const body = this.renderer.selectRootElement('body', true);
    if (body && body.className.includes('mini-sidebar')) {
      this.renderer.removeClass(body, 'mini-sidebar');
      this.hadMiniSidebar = true;
    } else {
      this.hadMiniSidebar = false;
    }
  }

  onMouseOut(): void {
    const body = this.renderer.selectRootElement('body', true);
    if (this.hadMiniSidebar) {
      this.renderer.addClass(body, 'mini-sidebar');
    }
  }

  ngOnDestroy(): void {
    this.subscriptionsRef.forEach(subscriptionRef => {
      subscriptionRef.unsubscribe();
    });
  }
}
