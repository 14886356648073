<ng-container *ngIf="!showOnlyMainComponent">
<header class="topbar">
  <nav class="navbar top-navbar navbar-expand-md navbar-light">
    <div class="navbar-collapse" *ngIf="!hideHeader">
      <ul class="navbar-nav mr-auto mt-md-0">
        <li class="nav-item">
          <a
            class="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"
            href="javascript:void(0)"
            ><i class="mdi mdi-menu"></i
          ></a>
        </li>
        <li *ngIf="showMenu" class="nav-item navbar-switch-container">
          <a
            #toggleSidebarMenu
            class="nav-link sidebartoggler hidden-sm-down navbar-switch"
            (click)="toggleStatusMenu()"
            href="javascript:void(0)"
            ><i class="mdi mdi-arrow-left-right"></i
          ></a>
        </li>
      </ul>
      <a
        *ngIf="!selectAccountEnabled"
        (click)="selectAccountEnabled = true"
        class="label account"
      >
        <span class="header-title">
        {{"common.account" | translate }}
        </span>
        <span class="header-selected">
          {{ accountName }}<i class="fa fa-chevron-down"></i>
        </span>
      </a>
      <ng-select2
        *ngIf="selectAccountEnabled"
        class="custom-select2"
        [options]="remoteOptions"
        (valueChanged)="switchAccount($event)"
      ></ng-select2>
      <button
        *ngIf="selectAccountEnabled"
        (click)="selectAccountEnabled = false"
        class="btn btn-close-account bg-transparent"
      >
        <i class="mdi mdi-close"></i>
      </button>
      <a
        *ngIf="!selectCatalogEnabled"
        (click)="selectCatalogEnabled = true"
        class="label account text-white"
      >
        <span class="header-title">
          {{"common.catalog" | translate }}
        </span>
        <span class="header-selected">
          {{ catalogName }}<i class="fa fa-chevron-down"></i>
        </span>
      </a>
      <ng-select2
        *ngIf="selectCatalogEnabled"
        class="custom-select2"
        [options]="catalogOptions"
        (valueChanged)="switchCatalog($event)"
      ></ng-select2>
      <button
        *ngIf="selectCatalogEnabled"
        (click)="selectCatalogEnabled = false"
        class="btn btn-close-account bg-transparent"
      >
        <i class="mdi mdi-close m-auto"></i>
      </button>
      <ul class="navbar-nav my-lg-0">
        <li class="nav-item dropdown">
          <app-language [showCode]="false"></app-language>
        </li>
        <li class="nav-item dropdown">
          <app-usermenu></app-usermenu>
        </li>
      </ul>
    </div>
  </nav>
</header>
<app-menu></app-menu>
</ng-container>